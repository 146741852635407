import { format, parseISO } from "date-fns";
import React, { useRef, useState } from "react";
import { CafeLogo } from "src/assets";
import { parseTransactionString, renderNaira } from "src/utils/functions";
import Modal from "./Modal";
import VerifyPin from "src/modal/VerifyPin";
import { useReactToPrint } from "react-to-print";
import Barcode from "react-barcode";

const TicketCard = ({ ticket }) => {
  const [isPinModalOpen, setIsPinModalOpen] = useState(false);
  const details = parseTransactionString(ticket?.details);

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      printRef.current = null;
      window.location.reload();
    },
  });

  const QR = ticket?.order_id?.split("-").pop();
  const qrCode = `https://cbt-api.examcentre.ng/qrcode/?qr=${QR}`;

  return (
    <div
      ref={printRef}
      className="my-[3rem] border-[0.2px] border-[#E6E7E9] bg-white  p-[3rem] rounded-[20px]"
    >
      <div className="flex justify-center items-center gap-[1rem] flex-col mb-[4rem]">
        <img src={CafeLogo} alt="logo" width="50" />
        <p className="text-primary text-[1.5rem] font-semibold">PAU CAFE</p>
      </div>
      <div className="flex justify-between items-center mb-[2rem]">
        <p className="text-primary text-[1.8rem] font-semibold">
          #{ticket?.order_id?.split("-")?.pop()}
        </p>
        <p className="text-primary text-[1.4rem] font-semibold">
          {format(parseISO(ticket?.dateTime), "dd MMM yyyy, HH:mm a")}
        </p>
      </div>
      {details?.items?.map((item, i) => (
        <div className="flex justify-between items-center my-[2rem]" key={i}>
          <p className="text-primary text-[1.4rem] font-normal">
            <span className="font-semibold text-primary">
              {item?.quantity}x
            </span>{" "}
            {item?.name}
          </p>
          <p className="text-primary text-[1.4rem] font-normal">
            {renderNaira(item?.total)}{" "}
          </p>
        </div>
      ))}

      <div className="flex justify-between items-center my-[2rem]">
        <p className="text-primary text-[1.6rem] font-semibold">Total</p>
        <p className="text-primary text-[1.6rem] font-medium">
          {renderNaira(details?.total)}
        </p>
      </div>
      <div className="flex justify-center items-center my-[2rem]">
        {/* <Barcode displayValue={false} value={QR} width={1.2} height={40} /> */}

        <img src={qrCode} alt="ticket-img" width={80} />
      </div>

      <button
        type="button"
        className="print bg-primary text-white rounded-[2.5rem] w-full flex justify-center items-center h-[4.6rem]"
        onClick={() => setIsPinModalOpen(true)}
      >
        Print Ticket
      </button>

      <Modal
        isOpen={isPinModalOpen}
        setIsOpen={setIsPinModalOpen}
        title="Pin Verification"
        size={"sm"}
      >
        <VerifyPin
          setIsPinModalOpen={setIsPinModalOpen}
          details={ticket}
          handlePrint={handlePrint}
        />
      </Modal>
    </div>
  );
};

export default TicketCard;
