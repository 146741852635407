import { format, parseISO } from 'date-fns';
import React from 'react'
import { parseTransactionString, renderNaira } from 'src/utils/functions'

const OrderDetailsCard = ({ hasButton = false, orders }) => {

    const details = parseTransactionString(orders?.details);

    return (
        <div className='my-[3rem] border-[0.2px] border-[#E6E7E9] bg-white  p-[3rem] rounded-[20px]'>
            {orders?.status === "ongoing" && orders?.isExpired === true && (<div className='text-red-500 text-[1.5rem] font-normal mb-3 flex justify-end'>Expired</div>)}
            <div className='flex justify-between items-center mb-[2rem]'>
                <p className='text-primary text-[1.8rem] font-semibold'>{orders?.order_id?.split("-").pop()}</p>
                <p className='text-primary text-[1.6rem] font-semibold'>{format(parseISO(orders?.dateTime), "dd MMM yyyy, HH:mm a")}</p>
            </div>
            {details?.items?.map((item, i) => (

                <div className='flex justify-between items-center my-[2rem]' key={i}>
                    <p className='text-primary text-[1.6rem] font-normal'><span className="font-semibold text-primary">{item?.quantity}x</span> {item?.name}</p>
                    <p className='text-primary text-[1.6rem] font-normal'>{renderNaira(item?.total)} </p>
                </div>
            ))}


            <div className='flex justify-between items-center my-[2rem]'>
                <p className='text-primary text-[1.6rem] font-semibold'>Total</p>
                <p className='text-primary text-[1.6rem] font-medium'>{renderNaira(details?.total)}</p>
            </div>

            {hasButton && (
                <div className='bg-primary text-white rounded-[2.5rem] w-full flex justify-center items-center h-[4.6rem]' >
                    Repeat Order
                </div>
            )}


        </div>
    )
}

export default OrderDetailsCard